/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Theme Color
2.0 Fonts
		2.1 Font Size
		2.2 Font Wieght
3.0 Heading Size
--------------------------------------------------------------*/

// 1.0 Theme Color
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #faa61a !default;
$green: #439c7c !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: #b11116 !default;
$secondary: #f7f3ec !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: #e5f8f4 !default;
$dark: #1f0b0b !default;
$blue: $blue !default;

// 2.0 Fonts
// 2.1 Font Size
$font-size-base: 0.875rem !default; //0.875 ~14px
$font-size-lg: ($font-size-base * 1.143) !default; // ~16px
$font-size-sm: ($font-size-base * 0.857) !default; // ~12px
$font-size-xs: ($font-size-base * 0.714) !default; // ~10px

// $font-open-sans : 'Open Sans', sans-serif;

// border radius
// $border-radius:               .25rem !default;
// $border-radius-lg:            .5rem !default;
// $border-radius-sm:            .2rem !default;

// 2.2 Font weight
$font-semi-bold: 600 !default;
$font-weight-bolder: 900 !default;

// 3.0 Heading Size
$h1-font-size: ($font-size-base * 2.857) !default; // ~40px
$h2-font-size: $font-size-base * 2.286 !default; // ~32px
$h3-font-size: ($font-size-base * 2) !default; // ~28px
$h4-font-size: ($font-size-base * 1.571) !default; // ~22px
$h5-font-size: ($font-size-base * 1.286) !default; // ~18px
$h6-font-size: $font-size-lg !default;

// modal
$zindex-modal: 1040 !default;
