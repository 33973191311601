.progress {
  display: inline-flex;
  vertical-align: middle;
  width: 250px;
  max-width: 100%;
  margin: 0rem 1rem;
  height: 0.5rem;
  background-color: lighten($primary, 43%);

  &.full-length {
    width: 100%;
    margin: 0;
  }
}
