.barchart {
    &-box {
        position: relative;
    }
    &-refrence {
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        top: 30px;
        p {
            width: 155px;
        }
    }
}