.badges {
  padding: 0.2rem 0.8rem;
  border-radius: 20px;
  line-height: 1;
  display: inline-block;

  &-success {
    color: $green;
    background: lighten($green, 48%);
  }
  &-warning {
    color: $yellow;
    background: lighten($yellow, 40%);
  }
  &-secondary {
    color: $gray-600;
    background: $gray-100;
  }
  &-danger {
    color: $red;
    background: lighten($red, 40%);
  }

  &-outline {
    &-success {
      color: $green;
      border: 1px solid currentColor;
    }
    &-warning {
      color: $yellow;
      border: 1px solid currentColor;
    }
    &-secondary {
      color: $gray-600;
      border: 1px solid currentColor;
    }
    &-danger {
      color: $red;
      border: 1px solid currentColor;
    }
  }

  &.active {
    background: $green;
    color: $white;
  }
}
