* {
  margin: 0;
  padding: 0;
}

body,
html {
  color: #595959 !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  background: white;
}

input {
  outline: none;
}

button,
select,
a {
  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
    outline: 0;
  }
}

select {
  // appearance: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  cursor: pointer;
}

// ul{
//     list-style: none;
//     margin: 0;
//     padding: 0;
// }

a {
  text-decoration: none;
  color: $green;

  &:hover,
  &:focus {
    text-decoration: none;
    color: darken($green, 20%);
  }
}

.object-fit {
  height: 140px;
  width: 100%;
  object-fit: contain;
}

.custom-control-label {
  cursor: pointer;
}

section {
  @extend .fade-in;
}

.fade-in {
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  -webkit-animation: fadein 1s;
  -o-animation: fadein 1s;
  zoom: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
