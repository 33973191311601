.header-main {
  img {
    max-height: 40px;
  }
}

.form {
  &--container {
    width: 100%;
    padding: 1rem 1.25rem;
  }

  &--wrapper {
    max-width: 1400px;
    margin: auto;

    .react-datepicker-wrapper {
      display: block;
    }

    .remove-fiels {
      position: absolute;
      right: 10px;
      top: -20px;
      z-index: 1;
      font-size: 25px;
      padding: 0;
      line-height: 1;
      opacity: 0.6;

      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }

    .cmn-remove {
      margin-top: -25px;
    }
  }
}
