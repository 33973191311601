$primary-color: var(--primary);
.form-control:focus {
  --box-shadow: var(--primaryWithOpacity);
  box-shadow: 0 0 0 0.2rem var(--box-shadow) !important;
  border-color: var(--primary) !important;
}

.forget-pass {
  a {
    color: var(--primary) !important;
  }
}
.landing-button {
  background: $primary-color;
  border-color: $primary-color;
  color: $white;
  &:focus {
    --box-shadow: var(--primaryWithOpacity);
    box-shadow: 0 0 0 0.3rem var(--box-shadow) !important;
  }
  &:hover {
    color: rgba(255, 255, 255, 0.66) !important;
  }
  &:active {
    --box-shadow: var(--primaryWithOpacity);
    box-shadow: 0 0 0 0.3rem var(--box-shadow) !important;
  }
}
.landing-outline-button {
  border-color: $primary-color;
  color: $primary-color;
  &:hover {
    background-color: $primary-color !important;
    color: $white !important;
  }
  &:focus {
    --box-shadow: var(--primaryWithOpacity);
    box-shadow: 0 0 0 0.3rem var(--box-shadow) !important;
  }
}
.apply-button {
  color: $primary-color;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
    box-shadow: none;
  }
}
.prog-pwd {
  height: 100vh;
  background: var(--primaryWithOpacity);
}
.modal-header {
  background: var(--primaryWithOpacity) !important;
}
.criteria-box {
  &-title {
    background-color: var(--secondary);
  }
}
.submit-sucess-title {
  color: $primary-color;
}
.upload--here {
  label {
    color: $primary-color;
  }
}
.form-details {
  color: $primary-color;
}
