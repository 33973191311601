.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}

.form-check-label {
  > input {
    margin-right: 10px;
  }
}
