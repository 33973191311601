// For call mixin --->

/**---Triangle---**/
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;

  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

/**---Box Shadow---**/
@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $size $color;
    -moz-box-shadow: inset $top $left $blur $size $color;
    box-shadow: inset $top $left $blur $size $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
  }
}

@mixin typo($base-font-family, $font-size, $font-weight, $line-height) {
  font-family: $base-font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin transition-css($transition-property, $transition-duration, $timing-function) {
  -webkit-transition: $transition-property $transition-duration $timing-function;
  -moz-transition: $transition-property $transition-duration $timing-function;
  -o-transition: $transition-property $transition-duration $timing-function;
  transition: $transition-property $transition-duration $timing-function;
}

@mixin rotate($deg) {
  transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
}

@mixin transfrom3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -o-border-radius: $radius;
}

@mixin placeholder-font($ph-color, $font-weight) {
  &.placeholder {
    color: $ph-color;
    font-weight: $font-weight;
  }
  &:-moz-placeholder {
    color: $ph-color;
    font-weight: $font-weight;
  }
  &::-webkit-input-placeholder {
    color: $ph-color;
    font-weight: $font-weight;
  }
  &:-ms-input-placeholder {
    color: $ph-color;
    font-weight: $font-weight;
  }
}

@mixin translate($left, $top) {
  -webkit-transform: translate($left, $top);
  -moz-transform: translate($left, $top);
  -ms-transform: translate($left, $top);
  -o-transform: translate($left, $top);
  transform: translate($left, $top);
}

@mixin only-for-mobile {
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    @content;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    @media (orientation: landscape) {
      @content;
    }
  }
}

@mixin only-for-tablet {
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin only-for-desktop {
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    @content;
  }
}
@mixin flex-align($property, $content, $item) {
  display: $property;
  justify-content: $content;
  align-items: $item;
}
