.navbar-light {
  background-color: #f3f7fa !important;
  .navbar-brand {
    text-align: center;
    cursor: pointer;
    &.lg {
      width: 100px;
      background: #0d363d;
      padding: {
        top: 1rem;
        bottom: 1rem;
      }

      margin: {
        left: -1rem;
        top: -0.5rem;
        bottom: -1rem;
      }

      svg {
        width: 40px;
      }
    }
  }

  .user-img {
    width: 44px;
    height: 44px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }

  .feedbacks {
    //feedback icon
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    &--img {
      width: 100%;
      padding: 7px;
    }
    &:hover {
      box-shadow: 0px 3px 6px #00000011;
    }
  }
}

.navbar {
  box-shadow: 0 3px 6px 1px rgba($black, 0.1);
}
