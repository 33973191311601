.esewa {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;

  .payment {
    border: none;

    .modal-content {
      border: none;
      border-radius: 16px;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0%), 0 0px 26px 0 rgba(0, 0, 0, 23%);
    }
    .header {
      display: flex;
      justify-content: center;
      padding: 2rem;
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }
    .header .heading {
      color: #439c7c;
      font-size: 25px;
    }
    .icon img {
      height: 50px;
    }
    .payment .table {
      text-align: left;
      display: flex;
      justify-content: center;
    }
    .payment .table td,
    .table th {
      border-top: none;
    }
    .payment .table th {
      padding-right: 11rem;
      font-weight: 400;
      color: #7d8585;
    }
    .payment .table td {
      color: #182929;
      font-weight: 600;
    }
    .payment-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1.5rem;
      border-top: 1px solid #e9ecef;
    }
    .btn-green {
      color: #fff;
      background-color: #439c7c;
      border-color: #439c7c;
    }
  }
}
