.server-error {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;

  svg {
    margin: 10px;
  }
}

.error span.required,
.required-details {
  position: absolute;
  font-size: $font-size-sm;
}

.form-check-inline {
  span.required {
    position: relative;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .error span.required,
  .required-details {
    position: absolute;
    bottom: -24px;
    left: 0;
  }
}
