@include only-for-tablet {
  .app {
    &__wrapper {
      .left-side {
        display: none;
      }

      .right-side {
        margin: auto;

        .logo-sm {
          display: block;
        }
      }
    }
  }
  .standards {
    .login-landing {
      .left-side .left-contents .logo {
        margin-top: 50px !important;
      }
      .right-side {
        width: 100%;
        .app__container {
          padding: 40px;
          display: flex;
          justify-content: center;
          height: auto;
          .signup,
          .login {
            width: 100% !important;
          }
        }
      }
    }
  }
  .header-main {
    padding: 12px 15px;

    img {
      max-width: 86px;
    }
  }

  .nav-tabs {
    margin: 1rem auto;

    &.nav-fill {
      .nav-link {
        margin: 0 10px;

        .text {
          margin-left: 10px;
          display: none;
          position: absolute;
          left: 15px;
        }

        .proc {
          position: relative;
          min-height: 9px;
          display: block;
        }

        .img {
          display: none;

          img {
            width: 23px;
          }
        }

        .img,
        .num {
          position: absolute;
          top: -22px;
          z-index: 1;
          left: -22px;
        }

        .num {
          display: block;
          width: 25px;
          height: 25px;
          line-height: 23px;
          text-align: center;
          border: 1px solid currentColor;
          border-radius: 50%;
        }

        &.active {
          color: $yellow;

          .text {
            margin-left: 0px;
            display: block;
            left: 0;
          }
        }

        &.done {
          color: $primary;

          // .num {
          //   display: none;
          // }

          .img {
            display: inline;
          }
        }
      }
    }
  }

  .landing-cover {
    .app__container {
      display: block;
    }
  }
  .resource {
    &-title {
      h4 {
        font-size: 16px;
      }
    }
    &-body {
      font-size: 14px;
    }
    &-sub-title {
      font-size: 14px;
    }
  }
  .login-landing {
    height: auto;
    .left-side {
      width: 100%;
      max-width: 100%;
      display: block;
      .left-content {
        padding: 40px;
        width: 100%;
        margin: auto;
      }
    }
    .right-side {
      width: 100%;
      .app__container {
        height: fit-content;
        .right-side {
          margin-left: 0px;
        }
      }
    }
    .app__container .right-side.signup {
      height: fit-content;
    }
  }
}
