.accordion {
  .link {
    cursor: pointer;
    display: block;
    margin: 0;
    border-bottom: 1px solid $border-color;

    @extend .d-flex;
    @extend .align-items-center;

    .icon-angle {
      @include transition-css(all, 300ms, ease-in-out);
    }
  }

  .collapse {
    &.show {
      + .link {
        color: $green;

        .icon-angle {
          @extend .icon-rotate-270;
        }
      }

      ~ .link {
        color: $green;
      }
    }
  }

  .collapsing {
    ~ .link {
      color: $green;

      .icon-angle {
        @extend .icon-rotate-270;
      }
    }
  }
}
