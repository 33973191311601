.v-align {
  top: 50%;
  @include translate(0, -50%);
}

.c-align {
  left: 50%;
  @include translate(-50%, 0);
}

.v-c-align {
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  line-height: 1.3 !important;
}

.h5,
.h6,
h5,
h6 {
  line-height: 1.5 !important;
}

small,
.small {
  font-size: $font-size-sm !important;
}

.xs-small {
  font-size: $font-size-xs;
}

.text-blue {
  color: #74b9ff;
}

.bg-green {
  background: #174e4d;
}

.bg-light-green {
  background-color: #f3f7fa;
}

.bg-grey-9 {
  background: #f7f3ec;
}

ul.disc {
  margin-left: 1rem;

  li {
    list-style: disc;
    @extend .mb-1;
  }
}

.font-weight-semibold {
  font-weight: $font-semi-bold !important;
}

.pointer {
  cursor: pointer;
}
.text-color-blue {
  color: $blue;
}
