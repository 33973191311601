.thames {
  /* Core variables and mixins */

  .login-landing {
    height: 100vh;
    display: flex;
    .left-side {
      width: 50%;
      max-width: 100%;
      display: inline-block;
      height: 100%;
      .left-contents {
        width: 480px;
        max-width: 100%;
        margin: auto 50px auto auto;
        color: #fff;

        .logo {
          background-color: #fff;
          padding: 5px;
          margin-top: 55px;
          width: fit-content;
          height: 100px;
          border-radius: 10px;
          img {
            height: 100%;
            max-width: 100%;
          }
        }

        p {
          font-size: 16px;
        }
      }
    }
    .main-text {
      font-weight: 600;
      margin-top: 20px;
      color: white;
      text-shadow: 4px 10px 10px #0000001a;
    }
    .sub-text {
      font-size: 16px;
      font-weight: 400;
      ul {
        list-style-type: none;
        li {
          font-size: 15px;
          font-weight: 400;
        }
        li:nth-child(1) {
          margin-bottom: 15px;
        }
      }
    }
    .criteria-box {
      height: fit-content;
      min-width: 230px;
      max-width: 400px;
      margin-right: 15px;
      &-title {
        padding: 5px 15px;

        color: White;
        font-weight: 600;
      }
      ul {
        list-style: none;
        color: #595959;
        li {
          padding: 0px 15px;
          line-height: 30px;
          display: flex;
        }
        .gpa {
          background-color: #dfecfa;
          color: #595959;
        }
      }
    }
  }

  h1.title {
    font-size: 24px;
    font-weight: $font-semi-bold;
  }

  .application-list {
    a:hover {
      text-decoration: none;
    }
    .application {
      &--title {
        color: var(--primary);
      }
      table {
        tr > :first-child {
          color: $gray-600;
        }
      }
    }
  }
  .form--wrapper {
    .nav-tabs.nav-fill .nav-link.done {
      color: var(--primary);
    }
    .nav-tabs.nav-fill .nav-link.active {
      color: var(--secondary);
      background: transparent;
      border-color: currentColor;
    }
  }
  .header-main {
    .logo--wrapper {
      height: 50px;
    }
    img {
      max-height: 50px;
    }
    .content-appoint {
      margin-left: 35px;
    }
  }
  .help--msg-block {
    max-width: 400px;
    padding: 10px 20px 10px 50px;
  }
  .upload--here {
    border-radius: 20px;
  }
  .application-header--title {
    font-weight: 600;
  }
  .nav-tabs .nav-link {
    color: var(--secondary);
  }
}

@include only-for-mobile {
  .thames {
    .login-landing {
      flex-direction: column;
      .left-side {
        width: 100%;
        .left-contents {
          padding: 20px 20px 30px 20px;
          margin-top: 0px;
          .logo {
            height: 50px;
          }
          .main-text {
            margin-top: 10px;
          }
        }
      }
      .right-side {
        padding: 20px 0px;
      }
    }
    .header-main {
      .contact-email {
        flex-direction: column;
        align-items: flex-start;
      }
      .content-appoint {
        margin-left: 0px;
        margin-top: 20px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .login-landing {
    flex-direction: column;
    .left-side {
      width: 100%;
      .left-contents {
        padding: 0px 20px 30px 20px;
        margin-top: 0px;
        .logo {
          margin-top: 0px !important;
        }
        h1.main-text {
          margin-top: 20px;
          font-size: 32px;
        }
      }
    }
  }
}

@media screen and (min-width: 1620px) {
  .thames {
    .login-landing {
      .left-side {
        .left-contents {
          width: 580px;
          margin: 100px 50px auto auto;
          .logo {
            margin-top: 60px;
            width: fit-content;
            height: 150px;
            border-radius: 10px;
            img {
              height: 100%;
              max-width: 100%;
            }
          }
          .main-text {
            margin-top: 65px;
          }
          .sub-text {
            font-size: 16px;

            ul {
              li {
                font-size: 16px;
              }
              li:nth-child(1) {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
