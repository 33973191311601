.css-b8ldur-Input {
  input[type='text']:focus {
    outline: $white !important;
  }
}

.css-1hwfws3 {
  .css-1g6gooi {
    opacity: 0;
  }
}
