.user {
  .dropdown-toggle:after {
    display: none;
  }
  .user-img {
    height: 44px;
    width: 44px;
    border-radius: 20%;
    border: 1px solid #f3f7fa;
  }
  .dropdown-user__info {
    min-width: 15rem;
    // margin: 10px 20px;

    img {
      display: inline-block;
      vertical-align: top;
      width: 60px;
      margin-bottom: 5px;
    }
  }
}

.dropdown {
  &-toggle {
    &.no-caret {
      &:after {
        display: none;
      }
    }
  }
  &-menu {
    border: 1px solid #dbdbdb;
    @include box-shadow(1px, 1px, 3px, 0, rgba($black, 0.1));
    padding: 0;

    ul {
      list-style: none;
      margin-bottom: 0;
    }
  }
  &--item {
    @include transition-css(all, 0.2s, ease-in-out);
    padding: 10px 20px;
    cursor: pointer;
    .user {
      &-info {
        width: convertRem(290px);
        display: grid;
        grid-template-columns: 48px calc(100% - 58px);
        grid-column-gap: 10px;
        align-items: center;
        margin-left: 0;
      }
      &--img-hold {
        @include box(48px);
        border-radius: 8px;
        @include flex-align(inline-flex, center, center);
        overflow: hidden;
        img {
          max-width: 100%;
        }
      }
      // &--email{
      //     overflow-wrap: break-word;
      // }
    }
    &:hover {
      background: $green;
      a,
      .user--email {
        color: $white;
      }
    }
    a {
      display: block;
    }
  }
}
