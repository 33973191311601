.table {
  th {
    color: $green;
    text-transform: uppercase;
    font-size: $h6-font-size;
    font-weight: $font-semi-bold;

    &.sortable {
      &[aria-label='desc'] {
        background: red;
      }
      .order {
        @include box(32px);
        position: absolute;
        top: 10px;

        .dropup,
        .dropdown {
          display: block;
          position: absolute;
          left: 50%;
          @extend .v-c-align;
          .caret {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
          }
        }

        .dropup {
          top: 7px;
          .caret {
            border-width: 0 5px 5px 5px;
            border-color: transparent transparent $green transparent;
          }
        }

        .dropdown {
          bottom: -7px;
          .caret {
            border-width: 5px 5px 0 5px;
            border-color: $green transparent transparent transparent;
          }
        }
      }
    }
  }

  th,
  td {
    border-top: 0;
  }
}
