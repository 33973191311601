.empty-state-wrapper {
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .empty-state-title {
    font-size: 21px;
    font-weight: 700;
    color: $green;
    margin: 20px 10px 0px 10px;
  }

  .empty-state-info {
    padding: 10px;
    width: 250px;
  }
}
