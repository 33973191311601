.btn {
  &:disabled {
    pointer-events: none;
  }
  &--white {
    background: $white;
    color: $green;
    border: 1px solid $green;
  }
}
