.boston {
  $primary: #253f8c;
  $yellow: #f15b2d;
  /* Core variables and mixins */
  @import '../vandors/bootstrap4.4.1/bootstrap.min';

  //nav-items
  .nav-tabs.nav-fill .nav-link.active {
    color: $yellow;
    background-color: transparent;
    border-color: $yellow;
  }
  a.time {
    text-decoration: none;
  }
  .header-main .zoom-conselor {
    color: $primary;
    &:hover {
      .label {
        color: $primary !important;
        .time {
          color: #595959;
        }
      }
    }
    .label .time {
      display: block;
    }
  }
  .header-main .logo--wrapper {
    height: 38px;
  }
  .login-landing {
    .left-side {
      background: url('/images/boston-landing.jpg') no-repeat;
      background-size: cover;
      background-position: top;
      .left-content {
        .logo {
          height: 95px;
          margin-top: 19px;
          width: fit-content;
          background: transparent;
          img {
            height: 100%;
          }
        }
      }
    }
    .main-text {
      font-weight: 600;
      margin-top: 100px;
      font-size: 32px;
      color: white;
    }

    .criteria-box {
      height: fit-content;
      &-title {
        padding: 5px 15px;
        background-color: $yellow;
        color: White;
        font-weight: 600;
      }
      ul {
        list-style: none;
        color: #595959;
        li {
          padding: 0px 15px;
          line-height: 30px;
        }
        .gpa {
          background-color: white;
          color: $primary;
        }
      }
    }

    .app--form {
      margin-top: 1rem;
      a.text-red {
        color: $primary;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }

  .view-content .content-list-body {
    .sub-title {
      color: $primary;
    }
    .red-chart {
      .title {
        background-color: $primary;
        padding: 5px 15px;
        color: white;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  h1.title {
    font-size: 24px;
    font-weight: $font-semi-bold;
  }

  .footer {
    background-color: $primary;
    padding: 20px 20px;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 300;
    color: white;
    .logo {
      background-color: $white;
      padding: 10px;
      width: 125px;
      border-radius: 4px;
      height: 90px;
      margin-right: 100px;
      img {
        height: 100%;
      }
    }
    .contact {
      a {
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .application-list {
    a:hover {
      text-decoration: none;
    }
    .application {
      &--title {
        color: $primary;
      }
    }
  }
  .form--wrapper {
    .nav-tabs.nav-fill .nav-link.done {
      color: $primary;
    }
  }

  .prog-pwd {
    height: 100vh;
    background: lighten($primary, 55%);

    .logo {
      text-align: center;
      margin-bottom: 1.2rem;

      img {
        max-width: 165px;
      }
    }

    .form {
      max-width: 450px;
      width: 100%;
      margin: auto;
    }
  }
  .badges-success {
    color: $yellow;
    background: rgba($yellow, 0.12);
  }
  .upload--here label {
    color: $yellow;
  }
  .modal-header {
    background: rgba($primary, 0.12) !important;
  }
  .form-control:focus {
    border-color: $primary !important;
    box-shadow: 0 0 0 0.3rem rgba(37, 63, 140, 0.22) !important;
  }
  .submit-sucess-title {
    color: $primary !important;
  }
  .btn-primary,
  .landing-button {
    background: $primary;
    border-color: $primary;
    color: $white;
    &:hover {
      background: rgba(37, 63, 140, 0.88);
      border-color: $primary;
    }
    &:focus {
      background: rgba(37, 63, 140, 0.88);
      background-color: rgba(37, 63, 140, 0.88);
      border-color: $primary;
      box-shadow: 0 0 0 0.3rem rgba(37, 63, 140, 0.22) !important;
    }
    &:active {
      background: rgba(37, 63, 140, 0.88);
      background-color: rgba(37, 63, 140, 0.88) !important;
      border-color: $primary !important;
      box-shadow: 0 0 0 0.3rem rgba(37, 63, 140, 0.22) !important;
    }
  }
  .landing-outline-button {
    border-color: $primary;
    color: $primary;
    &:hover {
      background-color: $primary !important;
      color: $white;
    }
  }
  .application-list .application .closed-application {
    background: $primary;
  }
}
@media screen and (width: 1920px) {
  .boston {
    .login-landing {
      .left-side {
        .left-content {
          width: 500px !important;
          margin: auto 100px auto auto !important;
          .logo {
            margin-top: 50px !important;
            height: 100px !important;
          }
          .main-text {
            margin-top: 100px !important;
            font-size: 40px !important;
          }
        }
      }
    }
  }
}
