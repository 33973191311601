@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@font-face {
  font-family: 'Palatino Linotype';
  src: url(../../fonts/Palatino/pala.ttf);
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Palatino Linotype';
  src: url(../../fonts/Palatino/palab.ttf);
  font-style: normal;
  font-weight: 700;
}
