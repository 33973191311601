.breadcrumb {
  background: transparent;
  margin: 0;
  padding-left: 0;

  &-item {
    font-size: $h6-font-size;
    font-weight: 400;

    .active {
      color: $dark;
    }
  }
}
