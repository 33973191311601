.nav-tabs {
  margin: 1rem auto;

  &.nav-fill {
    border: none;
    position: relative;

    .nav-link {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      border: none;
      margin-right: 10px;
      border-top: 2px solid currentColor;
      border-radius: 0;
      background: transparent;

      padding: {
        left: 0;
        right: 0;
      }

      .proc {
        position: relative;
        min-height: 16px;
      }

      .text {
        > span {
          margin-right: 5px;
        }
      }

      .img,
      .num {
        margin-right: 5px;
        display: none;
      }

      &.active {
        color: $yellow;
      }

      &.done {
        color: $primary;

        .img {
          display: inline;
        }

        .text {
          > span {
            display: none;
          }
        }
      }
    }
  }
}

.tab-content {
  padding: 1rem 1.25rem 1rem 3.25rem;

  .title {
    font-size: 16px;
    margin-left: -1.8rem;

    img {
      margin-right: 6px;
    }
  }

  .btn-grp {
    border-top: 1px solid $border-color;
    @extend .mt-4;
    @extend .py-4;

    .btn {
      text-transform: uppercase;
      padding: {
        left: 1.5rem;
        right: 1.5rem;
      }
    }

    .cancel {
      color: $dark;
      margin-right: 25px;
      padding: {
        left: 0;
        right: 0;
      }

      @extend .ml-auto;
    }
  }
}
