/* Core variables and mixins */
@import '../vandors/bootstrap4.4.1/bootstrap.min';
.marga {
  .form-control:focus {
    box-shadow: 0 0 0 0.2rem #71717140 !important;
    border-color: #00000000 !important;
  }
  .login-landing .right-side .app__container .right-side {
    width: 76% !important;
    padding: 0px !important;
    margin-left: 0 !important;
  }

  .login-landing {
    height: 100vh;
    display: flex;
    .left-side {
      width: 50%;
      max-width: 100%;
      display: inline-block;
      height: 100%;
      .left-contents {
        display: flex;
        justify-content: center;
        height: 100%;
        div {
          width: 400px;
          max-width: 100%;
          margin: auto;
          color: #fff;
          .logo {
            background: transparent;
            height: 100px;
            width: 300px;
            margin: 0 0 5rem;
            img {
              height: 100%;
              max-width: 100%;
            }
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    .main-text {
      font: 900 45px 'Museo Sans 900';
      font-family: 'Museo sans 900', sans-serif;
      margin-top: 50px;
      color: white;
      text-shadow: 4px 10px 10px #0000001a;
    }
    .sub-text {
      font-size: 16px;
      font-weight: 400;
    }
  }

  h1.title {
    font-size: 24px;
    font-weight: $font-semi-bold;
  }

  .application-list {
    a:hover {
      text-decoration: none;
    }
    .application {
      &--title {
        color: var(--primary);
      }
      table {
        tr > :first-child {
          color: $gray-600;
        }
      }
    }
  }
  .form--wrapper {
    .nav-tabs.nav-fill .nav-link.done {
      color: var(--primary);
    }
    .nav-tabs.nav-fill .nav-link.active {
      color: var(--secondary);
      background: transparent;
      border-color: currentColor;
    }
  }

  @media (max-width: 575.98px) {
    .login-landing {
      flex-direction: column;
      .left-side {
        width: 100%;
        .left-contents {
          padding: 0px 20px 30px 20px;
          margin-top: 0px;
          .logo {
            height: 50px;
            margin-top: 0px !important;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .login-landing {
      flex-direction: column;
      .left-side {
        width: 100%;
        .left-contents {
          padding: 0px 20px 30px 20px;
          margin-top: 0px;
          .logo {
            height: 50px;
            margin-top: 0px !important;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1920px) {
    .login-landing .left-side .left-contents {
      width: auto;
      margin: auto;
      .logo {
        margin-top: 10px;
        height: 100px;
      }
      h1.main-text {
        margin-top: 50px;
        font-size: 45px;
      }
      .sub-text {
        font-size: 18px;
      }
      .criteria-box {
        background-color: white;
        min-width: 280px;
        max-width: 450px;

        &-title {
          padding: 8px 20px;
          font-size: 17px;

          color: White;
          font-weight: 600;
        }
        ul {
          list-style: none;
          color: #595959;
          li {
            padding: 8px 20px;
            line-height: 25px;
          }
        }
      }
    }

    .app__wrapper .right-side {
      width: 600px;
    }
    .app__container .right-side.signup {
      height: fit-content;
    }
  }
}
