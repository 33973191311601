.card {
  color: $dark;
  cursor: pointer;
  height: 100%;
  border: 0;

  @extend .shadow-sm;

  &-img-top {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    height: 100px;
    object-fit: cover;
    object-position: center;
  }

  .indicator {
    width: 15px;
    height: 15px;
  }

  &:hover {
    @extend .shadow;
  }

  &-title {
    font-size: $font-size-base;
    font-weight: $font-semi-bold;
    margin-bottom: 0;
  }

  .small {
    @extend .mr-auto;
  }
}
