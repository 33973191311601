.fuse-classroom {
  $primary: #439c7c;
  $yellow: #f7941e;
  /* Core variables and mixins */
  @import '../vandors/bootstrap4.4.1/bootstrap.min';

  @media screen and (max-height: 767px) {
    .login-landing .left-side .left-content .logo {
      height: 48px !important;
      margin-top: 100px !important;
    }
  }

  .login-landing {
    .left-side {
      background: url('/images/landing-cover-fuse.png') no-repeat;
      background-size: cover;
      background-position: top;
      .left-content {
        .logo {
          background: transparent;
          height: 48px;
          margin-top: 100px;
          img {
            height: 100%;
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
    .main-text {
      font-weight: 600;
      margin-top: 285px;
      color: white;
    }

    .criteria-box {
      height: fit-content;
      &-title {
        padding: 5px 15px;
        background-color: #ffa600;
        color: White;
        font-weight: 600;
      }
      ul {
        list-style: none;
        color: #595959;
        li {
          padding: 0px 15px;
          line-height: 30px;
        }
        .gpa {
          background-color: #dfecfa;
          color: $primary;
        }
      }
    }

    .app--form {
      margin-top: 1rem;
      a.text-red:hover {
        text-decoration: underline;
        color: $primary;
      }
    }
  }

  .view-content .content-list-body {
    .sub-title {
      color: $primary;
    }
    .red-chart {
      .title {
        background-color: $primary;
        padding: 5px 15px;
        color: white;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  h1.title {
    font-size: 24px;
    font-weight: $font-semi-bold;
  }

  .footer {
    background-color: $primary;
    padding: 20px 20px;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 300;
    color: white;
    .logo {
      background-color: $white;
      padding: 10px;
      width: 125px;
      border-radius: 4px;
      height: 90px;
      margin-right: 100px;
      img {
        height: 100%;
      }
    }
    .contact {
      a {
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .application-list {
    a:hover {
      text-decoration: none;
    }
    .application {
      &--title {
        color: $primary;
      }
      table {
        tr > :first-child {
          color: $gray-600;
        }
      }
    }
  }
  .form--wrapper {
    .nav-tabs.nav-fill .nav-link.done {
      color: $primary;
    }
    .nav-tabs.nav-fill .nav-link.active {
      color: #fcb83f;
      background: transparent;
      border-color: currentColor;
    }
  }
}
//kist
