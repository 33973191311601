.scrollbar {
  --scrollbarBG: #cfd8dc;
  --thumbBG: $green;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  overflow: auto !important;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      color-stop(0.5, rgba(255, 255, 255, 0.2)),
      color-stop(0.5, transparent),
      to(transparent)
    );
  }
}

.h-80 {
  height: calc(100vh - 140px);

  @extend .scrollbar;
}
.s-h-80 {
  height: calc(100vh - 160px);
  @extend .scrollbar;
}
