.text-red {
  color: $primary;
}
.text-white {
  color: white;
}
.font-20 {
  font-size: 20;
}
.bg-light-yellow {
  background-color: rgb(248, 236, 215);
}

.overflow {
  overflow-x: auto;
}
.top-sticky {
  @supports (position: sticky) {
    position: sticky;
    top: 50px;
    z-index: $zindex-sticky;
  }
}
.zoom--block {
  position: fixed;
  bottom: 24px;
  right: 16px;
  z-index: 1030;

  .zoom-icon {
    cursor: pointer;
    left: 57px;
    height: 49px;
    position: relative;
    top: 65px;
    img {
      height: 100%;
    }
  }
  &.zoom--msg-block {
    width: 325px;
    height: 60px;
    border-radius: 50px 10px 10px 50px;
    transition: 0.2s ease-in-out;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.123);
    background-color: $primary;
    display: flex;
    justify-content: center;
    .zoom-icon {
      display: none;
      transition: 0.2s ease-in-out;
    }
    .zoom-online-content {
      visibility: visible;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.2s ease-in-out;

      a.join-btn {
        width: 40px;
        padding: 10px 27px;
        background: white;
        border-radius: 6px;
        color: $primary;
        margin-left: 20px;
        &:hover {
          background: #fbf2f2;
        }
      }
    }
  }
  .zoom-online-content {
    visibility: hidden;
  }
}
// background: url("/images/Landing-cover-image.png") no-repeat;
// background-size: cover;
// background-position: 0 0;
// height: 425px;
// .landing-cover{
//     background: url("/images/landing-cover-image-2.png") no-repeat;
//     background-position: 0 0;
//     background-size: 50% 100%;
// }
.login-landing {
  height: 100vh;
  .left-side {
    width: 50%;
    max-width: 100%;
    display: inline-block;
    height: 100%;
    background: url('/images/landing-cover-image-2.png') no-repeat;
    background-size: cover;
    background-position: top;
    .left-content {
      width: 480px;
      max-width: 100%;
      margin: auto 50px auto auto;
      color: #fff;
      .logo {
        background-color: #fff;
        padding: 10px;
        border-radius: 6px;
        margin-top: 40px;
        height: 50px;
        width: fit-content;

        img {
          height: 100%;
        }
      }
    }
  }
  .right-side {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    .app__container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .right-side {
        width: 76% !important;
        padding: 0px !important;
        margin-left: 0 !important;
      }
      .signup {
        width: 76%;
      }
      .login {
        width: 76%;
      }
    }
  }
}

.main-text {
  font-weight: 600;
  margin-top: 100px;
  color: white;
  text-shadow: 4px 10px 10px #0000001a;
}
.resource {
  background-color: #fffaf2;
  padding: 25px;
  border-radius: 6px;
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffa600;
    margin: -25px;
    padding: 5px 20px;
    color: white;
    border-radius: 6px 6px 0px 0px;
  }
  &-body {
    margin-top: 35px;
    font-size: 14px;
    ul {
      list-style: none;
      li {
        line-height: 30px;
        &:hover {
          text-decoration: underline;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }
        a {
          color: #595959;
          &:hover {
            color: #595959;
          }
        }
      }
    }
  }
  &-sub-title {
    border-radius: 4px;
    background-color: white;
    color: $primary;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 16px;
    margin: 10px 0px;
  }
}

.header-content {
  text-align: center;
  padding: 90px 0px;
  color: white;

  .apply {
    background-color: white;
    color: $yellow;
    text-transform: uppercase;
    &:hover {
      box-shadow: 1px 1px 20px rgba(255, 255, 255, 0.575);
    }
    img {
      margin-left: 20px;
    }
  }
}
.sub-text {
  font-size: 16px;
  font-weight: 400;
  ul {
    list-style-type: none;
    li {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.criteria-box {
  background-color: white;
  min-width: 230px;
  max-width: 400px;
  &.heighted {
    height: 195px;
    li {
      padding: 8px 20px;
    }
  }
  &-title {
    padding: 5px 15px;
    font-size: 15px;

    color: White;
    font-weight: 600;
  }
  ul {
    list-style: none;
    color: #595959;
    li {
      padding: 5px 15px;
      line-height: 20px;
      display: flex;
    }
    .gpa {
      background-color: #fbf2f2;
      color: $primary;
    }
  }
}

.view-content {
  margin-top: 60px;

  .content-list {
    font-size: 16px;
    background-color: #fffaf2;
    margin-bottom: 10px;
    &-title {
      font-size: 20px;
      font-weight: 600;
      padding: 10px 20px;
      position: relative;
      background-color: #ffcd71;
      color: #000000;
      cursor: pointer;
      span {
        background-color: #ffa600;
        color: white;
        float: right;
        display: block;
        text-align: center;
        width: 50px;
        height: 50px;
        margin: -10px -20px;
        line-height: 50px;
        font-size: 24px;
        cursor: pointer;
      }
    }
    &-body {
      .content {
        margin-top: 20px;

        p > a.text-red:hover {
          color: $primary;
          text-decoration: underline;
        }
      }

      padding: 0px 20px;
      transition: all 0.3s ease-in-out;
      color: #707070;
      overflow: hidden;
      line-height: 26px;
      max-height: auto;
      ul,
      ol {
        margin-left: 20px;
      }
      .sub-title {
        color: $primary;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 20px;
        .sub {
          font-size: 18px;
          display: block;
          font-weight: 600;
        }
      }
      .sub-title-sub {
        color: $primary;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .format-chart {
        margin-bottom: 20px;
        table {
          width: 100%;
        }
        .title {
          background-color: $yellow;
          padding: 5px 20px;
          color: white;
          font-size: 18px;
          font-weight: 400;
        }
        .body {
          background-color: $white;
          padding: 20px;
        }
      }

      .red-chart {
        margin-bottom: 20px;

        .title {
          background-color: $primary;
          padding: 5px 15px;
          color: white;
          font-size: 20px;
          font-weight: 400;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        }
        .body {
          // background-color: #FBF2F2;
          padding: 10px;
          h6 {
            font-weight: 600;
            margin: 0;
          }
          p {
            a {
              color: $primary;
            }
          }
          table.yellow {
            width: 100%;
            margin-bottom: 20px;
            thead {
              background-color: $yellow;
              color: white;
              th {
                font-size: 16px;
                font-weight: 400;
                padding: 10px 25px;
              }
            }
            tbody {
              background-color: white;

              td {
                padding: 7px 25px;
              }
            }
          }
          table.yellow.scholarship {
            font-size: 14px;
            th {
              padding: 10px;
            }
            td {
              padding: 7px 5px;
            }
          }
          table.yellow.a-levels {
            font-size: 14px;
            th {
              padding: 10px;
            }
            td {
              padding: 7px 5px;
              p {
                margin: 0;
              }
            }
          }
        }
      }
      &.show {
        max-height: 100%;
      }
    }
  }
}
.help--block {
  position: fixed;
  bottom: 24px;
  right: 16px;
  z-index: 1030;

  .help-icon {
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.349);
    cursor: pointer;
    right: 0;
    height: 60px;
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 60px;
    border-radius: 50%;
    img {
      height: 100%;
    }
  }
  &.help--msg-block {
    max-width: 325px;
    padding: 10px 20px;
    height: fit-content;
    border-radius: 80px 10px 10px 80px;
    transition: 0.2s ease-in-out;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.123);
    background-color: $primary;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contact {
      color: #fff;
      text-align: end;
      margin-bottom: 20px;
      position: relative;
      visibility: visible;
      &:after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        right: 0;
        height: 1px;
        width: 95%;
        background: #ffffff69;
      }
      .content {
        display: flex;
        justify-content: flex-end;
      }
    }
    .help-icon {
      display: none;
      transition: 0.2s ease-in-out;
    }
    .help-online-content {
      visibility: visible;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.2s ease-in-out;
      .content {
        font-size: 14px;
      }
      a.join-btn {
        width: 40px;
        padding: 10px 27px;
        background: #fff;
        border-radius: 6px;
        margin-left: 20px;
        &:hover {
          background: #dfdfdf;
        }
      }
    }
  }
  .help-online-content,
  .contact {
    visibility: hidden;
  }
}

.resource-modal {
  .modal-content {
    .modal-body {
      padding: 25px 10px 10px 10px;
      .progress {
        width: 100%;
        margin: 0;
        height: 0.5rem;
        background-color: lighten($primary, 55%);
        div.progress-bar.bg-info {
          background: #b20200 !important;
          color: white !important;
        }
      }
    }
  }
}

.modal-content {
  .modal-body {
    div > button.btn.c-btn {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
  }
}
.contact-email {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  .content-title {
    margin-right: 15px;
    font-weight: $font-semi-bold;
    padding-right: 10px;
    border-right: 2px solid var(--primary);
  }

  .content {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
}
.info-miss {
  color: $yellow !important;
  font-size: 20px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .login-landing .left-side {
    width: 100% !important;
  }
  .submit-sucess.bache-std {
    width: 100%;
  }
  .application-list .application {
    width: 298px;
  }

  .logo {
    width: fit-content !important;
    border-radius: 6px;
    background: white;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  .trinity-footer .bachelor {
    display: flex;
    padding: 10px;
  }
  .help--block {
    &.help--msg-block {
      max-width: 325px;
      .contact {
        visibility: visible;
        max-width: 1000px;
        margin-bottom: 10px;
        &:after {
          content: ' ';
          position: absolute;
          bottom: -10px;
          right: 0;
          height: 11px;
          width: 95%;
          background: #ffffff;
        }
      }

      .help-online-content {
        visibility: visible;
        max-width: 1000px;
        .content {
          font-size: 14px;
        }
      }
    }
    .help-online-content,
    .contact {
      visibility: hidden;
      max-width: 0;
      overflow: hidden;
    }
  }
  .btn-grp .btn {
    padding: 0.5rem 0.5rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .login-landing .left-side .left-contents .logo {
    height: 48px !important;
    margin-top: 70px !important;
  }
  .bachelor-landing .logo {
    height: 110px !important;
  }
  h1.main-text {
    font-size: 32px !important;
  }
  h3.font-weight-semibold.text-white.mt-4 {
    font-size: 22px;
  }
  .sub-text.text-white.mb-2 {
    font-weight: 300;
  }
  .criteria-box ul li {
    padding: 4px 15px;
  }
  .criteria-box.heighted {
    height: 160px;
  }
}

@media screen and (min-width: 1920px) {
  .login-landing {
    height: 100vh;
    .left-side {
      .left-content {
        width: 500px;
        margin: auto 100px auto auto;
        .logo {
          margin-top: 120px;
          height: 65px;
        }
      }
    }
  }

  .main-text {
    margin-top: 170px;
    font-size: 43px;
  }
  .app__wrapper .right-side {
    width: 600px;
  }
  .app__container .right-side.signup {
    height: fit-content;
  }
}
