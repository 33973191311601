.custom-calander {
  &.react-datepicker {
    border-radius: 10px;
    border: 0;
    @extend .bg-light-green;
    font-family: 'Source Sans Pro', sans-serif;
    display: block;
    overflow: hidden;

    .react-datepicker__navigation--previous {
      border-right-color: $green;

      &:hover {
        border-right-color: darken($green, 20%);
      }
    }

    .react-datepicker__navigation--next {
      border-left-color: $green;

      &:hover {
        border-left-color: darken($green, 20%);
      }
    }

    .react-datepicker__month-container {
      width: 100%;
      @extend .bg-light-green;
    }

    .react-datepicker__year-read-view,
    .-datepicker__year-read-view:hover,
    .react-datepicker__month-read-view:hover,
    .react-datepicker__month-read-view {
      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow {
        border-top-color: $green;

        &:hover {
          border-top-color: darken($green, 20%);

          &:before {
            border-top-color: darken($green, 20%);
          }
        }
        &:before {
          border-top-color: $green;
        }
      }
    }

    .react-datepicker__header {
      @extend .bg-light-green;
      border: 0;
      padding: 0.5rem 0.4rem 0;

      .react-datepicker__day-names {
        font-size: $h6-font-size;
        font-weight: $font-semi-bold;
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 1.9rem;
      width: auto;
      display: inline-block;
    }

    .react-datepicker__day--selected {
      background: lighten($green, 25%);
      font-weight: $font-semi-bold;
    }

    .react-datepicker__day {
      color: #182929;
      font-size: $font-size-base;

      &:hover {
        background: lighten($green, 45%);
      }
    }

    .react-datepicker__day--disabled {
      color: #ccc;
    }

    .react-datepicker__week,
    .react-datepicker__day-names {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 1rem;

      > * {
        margin: 0;
      }
    }
  }

  &.lg {
    .react-datepicker__day-names {
      margin: 1rem 0 0;
    }

    .react-datepicker__day {
      line-height: 3rem;

      &:after {
        font-family: 'fuseclassroom', sans-serif !important;
        vertical-align: middle;
        margin-left: 0.5rem;
      }

      &.absent:after {
        content: '\e913';
      }

      &.present:after {
        content: '\e915';
        color: $blue;
      }

      &.holiday:after {
        content: 'H';
        font-size: 0.5em;
        color: $blue;
      }
    }
  }
}
