.acard {
  // width: 900px;
  // max-width: 99%;
  // margin: 25px auto;
  // font-family: 'Open Sans', sans-serif;
  color: #182929;

  .btn {
    text-transform: uppercase;
    font-size: 12px;
  }

  &--wrapper {
    width: 100%;
  }

  &-card {
    position: relative;
    width: 100%;
    padding: 1.25rem 2rem;
    background-color: $white;

    .bg-card {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url('/images/card-bg.svg') no-repeat;
      background-position: right;
    }

    .acard {
      &--top {
        position: relative;
        .title {
          font-size: 46px;
          font-weight: 700;
          color: $yellow;
          line-height: 1;
        }
        .subject {
          font-weight: 600;
          font-size: 20px;
          background: $yellow;
          display: inline-block;
          padding: 4px 8px;
          line-height: 1;
          color: $dark;
        }
      }

      &--content {
        display: flex;

        .left {
          padding: 12px 25px 12px 15px;
          @extend .align-self-end;

          img {
            width: 100px;
          }
        }

        .right {
          padding: 12px 25px 0;
          border-left: 4px solid $yellow;
          width: calc(100% - 120px);
          font-size: 16px;

          .identity {
            margin-top: 50px;

            .sign {
              border-top: 1px solid $dark;
              display: inline-block;
            }

            label {
              min-width: 125px;
              text-align: center;
              margin-right: 35px;

              &:last-child {
                margin: 0;
              }
            }
          }

          label {
            text-transform: uppercase;
            font-weight: 600;
            display: inline-block;
            margin-right: 5px;
          }

          .fil-colge {
            display: block;
            padding: 1rem 1.25rem;
            background: lighten($yellow, 40%);
          }

          ul {
            font-size: 12px;
          }
        }
      }
    }
  }
}
