.standards {
  /* Core variables and mixins */
  @import '../vandors/bootstrap4.4.1/bootstrap.min';

  .login-landing {
    height: 100vh;
    display: flex;
    .left-side {
      width: 50%;
      max-width: 100%;
      display: inline-block;
      height: 100%;
      .left-contents {
        width: 480px;
        max-width: 100%;
        margin: auto 50px auto auto;
        color: #fff;

        .logo {
          background-color: #fff;
          padding: 10px;
          margin-top: 50px;
          width: fit-content;
          height: 50px;
          background: transparent;
          img {
            height: 100%;
            max-width: 100%;
          }
        }

        p {
          font-size: 16px;
        }
      }
    }
    .main-text {
      font-weight: 600;
      margin-top: 100px;
      color: white;
      text-shadow: 4px 10px 10px #0000001a;
    }
    .sub-text {
      font-size: 16px;
      font-weight: 400;
      ul {
        list-style-type: none;
        li {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .criteria-box {
      height: fit-content;
      min-width: 230px;
      max-width: 400px;
      margin-right: 15px;
      &-title {
        padding: 5px 15px;

        color: White;
        font-weight: 600;
      }
      ul {
        list-style: none;
        color: #595959;
        li {
          padding: 0px 15px;
          line-height: 30px;
          display: flex;
        }
        .gpa {
          background-color: #dfecfa;
          color: #595959;
        }
      }
    }
  }

  h1.title {
    font-size: 24px;
    font-weight: $font-semi-bold;
  }

  .application-list {
    a:hover {
      text-decoration: none;
    }
    .application {
      &--title {
        color: var(--primary);
      }
      table {
        tr > :first-child {
          color: $gray-600;
        }
      }
    }
  }
  .form--wrapper {
    .nav-tabs.nav-fill .nav-link.done {
      color: var(--primary);
    }
    .nav-tabs.nav-fill .nav-link.active {
      color: var(--secondary);
      background: transparent;
      border-color: currentColor;
    }
  }
  // @media screen and (width: 1920px) {
  //   .login-landing {
  //     .left-side .left-contents {
  //       width: 525px !important;
  //     }
  //     .main-text {
  //       margin-top: 145px !important;
  //     }
  //   }
  // }
}
.date-picker {
  width: 100px;
  padding: 6px;
}

@media (max-width: 575.98px) {
  .login-landing {
    flex-direction: column;
    .left-side {
      width: 100%;
      .left-contents {
        padding: 0px 20px 30px 20px;
        margin-top: 0px;
        .logo {
          height: 50px;
          margin-top: 0px !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .login-landing {
    flex-direction: column;
    .left-side {
      width: 100%;
      .left-contents {
        padding: 0px 20px 30px 20px;
        margin-top: 0px;
        .logo {
          margin-top: 0px !important;
        }
        h1.main-text {
          margin-top: 20px;
          font-size: 32px;
        }
      }
    }
  }
}

@media screen and (min-width: 1550px) {
  .standards {
    .login-landing {
      .left-side {
        .left-contents {
          .logo {
            padding: 10px;
            margin-top: 100px;
            height: 60px;
          }
        }
      }
      .main-text {
        margin-top: 160px;
      }
    }
  }
}
