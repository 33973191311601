.trinity-landing {
  font-family: 'Palatino Linotype', sans-serif;
  .left {
    height: 100vh;
    display: flex;
    padding-left: 120px;
    align-items: center;
    color: white;
    background: linear-gradient(90deg, rgb(178, 2, 0) 0%, hsl(1, 100%, 35%) 56%, rgba(255, 255, 255, 1) 60%);

    .bg-image {
      height: 100vh;
      position: relative;
      z-index: 3;
      img {
        height: 100%;
      }
    }
    .title {
      font-size: 3.5rem;
      z-index: 5;
      margin-left: 120px;
      position: absolute;
      left: 0px;
      font-weight: 700;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    h3 {
      margin-bottom: 25px;
      font-weight: 300;
    }
    .program {
      background: $primary;
      color: white;
      margin-bottom: 20px;
      border-radius: 45px;
      font-size: 22px;
      width: 270px;
      &:hover {
        background: #980f13;
      }
    }
    .logo {
      position: absolute;
      bottom: 60px;
      right: 100px;
      height: 120px;
      img {
        height: 100%;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 64vw;
    padding: 10px 100px 0px 0px;
    text-align: right;
    background: #ffa600;
    color: white;
  }
}
.trinity {
  font-family: 'Palatino Linotype', sans-serif;
  .login-landing .left-side .left-content {
    .logo {
      background-color: #fff;
      padding: 10px;
      border-radius: 4px;
      margin-top: 26px;
      height: 100px;
      width: -moz-fit-content;
      width: fit-content;
    }
    .main-text {
      margin-top: 20px;
      font-size: 32px;
    }
    h3 {
      font-size: 22px;
    }
    .criteria-box {
      width: 230px;
      .criteria-box-title {
        padding: 5px 15px;
        font-size: 15px;
        background-color: #ffa600;
        color: #fff;
        font-weight: 600;
        ul .gpa {
          background-color: #fbf2f2;
          color: #b11116;
        }
      }
    }
  }
  .header-main {
    .logo--wrapper {
      height: 40px;
    }
  }
  .modal-header {
    background: rgba(177, 17, 22, 0.22) !important;
  }
  .form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(177, 17, 22, 0.22) !important;
  }
  .prog-pwd {
    height: 100vh;
    background: #fcdfe0;
  }
  .anyone-underline {
    text-decoration: underline;
    font-style: italic;
  }
}
.trinity-footer {
  background-color: $primary;
  padding: 20px 20px;
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  color: white;
  .logo {
    background-color: $white;
    padding: 10px;
    width: fit-content;
    border-radius: 4px;
    height: 100px;
    margin-right: 20px;
    img {
      height: 100%;
    }
  }
  .contact {
    a {
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.bachelor {
    background-color: #ffa600;
    color: $dark;
    padding: 0;
    .logo {
      height: 135px;
      border-radius: 0;
    }
  }
}
.bachelor-landing {
  display: flex;
  .left {
    height: 100vh;
    display: flex;
    align-items: center;
    color: white;
    width: 55vw;
    background: linear-gradient(90deg, rgb(178, 2, 0) 0%, hsl(1, 100%, 35%) 56%, rgba(255, 255, 255, 1) 60%);

    .bg-image {
      height: 100vh;
      position: relative;
      left: 250px;
      z-index: 3;
      img {
        height: 100%;
      }
    }
    .title {
      font-size: 3rem;
      z-index: 5;
      margin-left: 90px;
      position: absolute;
      left: 0px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    .app__container .right-side {
      width: 100% !important;
      &.signup {
        height: fit-content !important;
      }
    }
  }
  .logo {
    margin: 35px 35px 0px 0px;
    position: absolute;
    right: 0px;
    height: 145px;
    img {
      height: 100%;
    }
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .trinity-landing {
    flex-direction: column;
    .left {
      height: 25vh;
      display: flex;
      padding-left: 0px;
      align-items: center;
      color: white;
      background: #b11116;
      position: relative;
      .bg-image {
        display: none;
      }
      .title {
        margin-left: 0;
        text-align: center;
        top: 0;
        left: 50%;
        transform: translate(-50%, 10%);
        font-size: 3rem;
      }
    }
    .right {
      margin-top: 30px;
      .logo {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translate(-50%, 10%);
        height: 90px;
        width: fit-content;
        img {
          height: 100%;
        }
      }
    }
    .footer {
      left: 0;
      width: 100%;
    }
  }
  .bachelor-landing {
    flex-direction: column;
    .left {
      height: 70vh !important;
      width: 100vw !important;
      background: #b11116 !important;
      position: relative;
      padding: 40px;
      .bg-image {
        display: none;
      }
      .title {
        margin-left: 0px !important;
        font-size: 2.7rem;
        z-index: 5;
        position: absolute;
        bottom: 0px;
        padding: 20px;
      }
    }
    .right {
      margin-top: 20px;
      width: 100%;
      .app__container {
        height: fit-content;
        padding: 40px;
        .right-side {
          margin-left: 0;
        }
      }
    }
    .logo {
      height: 100px;
    }
  }
  .trinity-footer.bachelor {
    padding: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1600px) {
  .bachelor-landing {
    .logo {
      height: 100px;
      img {
        height: 100%;
      }
    }
  }
  .trinity {
    .login-landing .left-side .left-contents .logo {
      height: 95px !important;
      margin-top: 22px !important;
    }
  }
}
@media screen and (min-width: 1920px) {
  .trinity {
    .login-landing .left-side .left-content {
      .logo {
        margin-top: 40px;
        height: 130px;
      }
      .main-text {
        margin-top: 200px;
        font-size: 40px;
      }
      h3 {
        font-size: 28px;
      }
    }
  }
}
