/* Custom Layout */
@import 'utilities/index';

/* Core variables and mixins */
@import 'vandors/bootstrap4.4.1/bootstrap.min';

/* base */
@import 'base/index';

/* fonts */
@import 'fonts/index';

/* Pages */
@import 'layout/index';

/* Layout */
@import 'pages/index';

/* custom component */
@import 'components/index';

/* third party */
@import 'vandors/index';

@import 'mediaqueries/index';
//

//Noty
@import 'noty/src/noty.scss'; // for success and error notifications
@import 'noty/src/themes/bootstrap-v4.scss'; // theme for noty
