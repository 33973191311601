.popup-notification-wrap {
  position: fixed;
  width: 340px;
  bottom: 20px;
  right: 40px;
  z-index: 99999;
  .popup-notification {
    display: none;
    width: 340px;
    height: 100px;
    position: relative;
    background: $white;
    z-index: 1060;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    &.show {
      display: block;
    }
    .discard {
      &:after {
        content: '\002B';
        position: absolute;
        top: 0px;
        right: 9px;
        font-size: 20px;
        transform: rotate(-44deg);
      }
    }
    .content {
      font-size: 12px;
      cursor: pointer;
      width: 100%;
      height: 100%;

      &:hover {
        background: #f3f7fa;
        z-index: -5;
      }

      .aside {
        .icon {
          position: absolute;
          left: 25px;
          top: 38px;
          z-index: 0;
          &:before {
            content: ' ';
            position: absolute;
            width: 35px;
            height: 35px;
            left: -6px;
            top: -4px;
            background-color: #e5f8f4;
            border-radius: 50%;
            z-index: -1;
          }
        }
      }
      .body {
        padding: 15px 30px 10px 70px;
        .title {
          font-size: 12px;
          font-weight: 900;
          margin-bottom: 5px;
          height: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .discription {
          line-height: 14px;
          font-size: 12px;
          height: 45px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
    }
    .branding {
      position: absolute;
      bottom: 5px;
      right: 10px;
      img {
        width: 80px;
      }
    }
  }
}

.notification-detail {
  .notification-title {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 5px;
  }
  .notification-content {
    .notification-message {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .notification-img {
      img {
        width: 100%;
      }
    }
  }
}

.side {
  &-notification {
    &-wrap {
      display: none;
      left: 0;
      top: 0;
      bottom: 0;
      position: fixed;
      z-index: 1025;
      background: rgba(0, 0, 0, 0.56);
      transition: right 0.2s ease-in-out,  opacity 0.2s ease-in-out;

      &.show {
        display: block;
        right: 0;
      }
    }

    &-close {
      height: 100%;
      margin-left: 400px;
    }

    position: fixed;
    width: 400px;
    background-color: $white;
    height: 100%;
    padding: 15px 0 0 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1050;
    transition: right 0.25s ease-in-out,  opacity 0.25s ease-in-out;

    hr {
      margin-bottom: 0px;
    }

    .close {
      margin-right: 1rem;
    }

    .notification-header {
      padding: 0 15px;
    }

    .notification-list {
      height: calc(100vh - 60px);
      padding-top: 0.5rem;

      @extend .scrollbar;

      .formatted-date {
        margin: 0 0 0 10px;
        text-transform: uppercase;
        @extend .py-2;
        font-weight: $font-semi-bold;
      }
      .notification {
        padding: 15px 10px 15px 15px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        cursor: pointer;
        margin-bottom: 1px;

        &.unread {
          border-left: 6px solid $info;

          &.title {
            font-weight: $font-semi-bold;
          }
          background: #f3f7fa;
        }

        &:hover {
          background: #f3f7fa;
        }

        .body {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          .title {
            font-weight: 600;
            margin: 0 0 5px 0;
            -webkit-line-clamp: 1;
          }

          .notification-img {
            width: 100%;
            height: 100%;
            padding: 10px 0px;
          }

          .message {
            -webkit-line-clamp: 2;
            font-size: 12px;
          }

          .date {
            font-size: 12px;
            margin-top: 10px;
          }

          .title,
          .message {
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
          }
        }

        .marker {
          margin-top: 5px;
          display: block;
          width: 12px;
          height: 12px;
          border: 2px solid $gray-400;
          cursor: pointer;
          border-radius: 50%;

          &:hover {
            border-color: lighten($green, 20%);
          }

          &.checked {
            background: $green;
          }
        }
      }
    }
    &__message {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      &.btm-message {
        padding-bottom: 10px;
      }
    }
  }
}
.notification-count {
  position: relative;
  .count-wrap {
    position: absolute;
    height: 20px;
    top: 0;
    right: 30px;
    width: 20px;
  }
  .count {
    position: absolute;
    right: -6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $red;
    color: $white;
    border-radius: 50%;
    font-size: 11px;
  }
}
