.title {
  font-size: 24px;
  font-weight: 600;
}

.header-main {
  padding: 12px 15px;
  position: relative;
  background: #fffcf9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @extend .shadow-sm;
  .logo--wrapper {
    height: 24px;
    img {
      height: 100%; // max-width: ;
    }
  }
  .header-zoom {
    display: flex;
    flex-direction: row-reverse;
  }
  .header--right {
    display: flex;
  }
  .header--menu {
    visibility: hidden;
  }
  .zoom-conselor {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
      color: $primary;
      .label {
        :nth-child(2) {
          text-decoration: underline;
        }
      }
    }
    .label {
      .time {
        font-size: 12px;
        display: none;
        color: #595959;
      }
    }
  }
}

.add--more {
  position: relative;
  text-align: center;

  .line {
    height: 1px;
    border-bottom: 1px solid $border-color;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
  }

  .btn {
    position: relative;
    z-index: 1;
    text-decoration: none;
    img {
      margin-right: 8px;
    }
  }
}

.upload--here {
  border: 1px dashed $border-color;
  border-radius: 2px;
  text-align: center;
  padding: 2rem 1.25rem;
  position: relative;

  > input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    width: 100%;
    opacity: 0;
  }
  > span {
    position: relative;
    z-index: 1;
  }

  label {
    cursor: pointer;
    color: $primary;
  }
}

.info-text {
  color: #bfbfbf;
  margin-top: 5px;
  text-align: center;
}

.prog-pwd {
  height: 100vh;
  background: lighten($primary, 55%);

  .logo {
    text-align: center;
    margin: auto;

    img {
      max-width: 165px;
    }
  }

  .form {
    max-width: 450px;
    width: 100%;
    margin: auto;
    padding-top: 3rem;
  }
}
.scrollbar-head {
  overflow: hidden;
  *,
  *::before,
  *::after {
    overflow: hidden;
  }
}
.apply-header {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  &--title {
    color: #595959;
    font-size: 18px;
    text-transform: uppercase;
  }
  &--border {
    border-right: 2px solid #e8e8e8;
    height: 30px;
  }
}
.error-scholar {
  font-size: 16px;
  text-align: center;
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .header-main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 20px 15px !important;
    .logo--wrapper {
      height: 24px;
      width: 100%;
      img {
        height: 100%;
        max-width: 100%;
      }
    }
    .header-zoom {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      .zoom-logo {
        img {
          width: 32px;
        }
      }
      > * {
        margin-top: 10px;
        font-size: 15px;
      }
    }
    .header--right {
      flex-direction: column;
      padding-top: 15px;
      z-index: 1000;
      display: none;
      &-open {
        transition: 0.5s ease-in-out;
        display: block;
        float: left;
        margin-right: auto;
        margin-top: 12px;
      }
    }
    .header--menu {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      visibility: visible;
      display: inline-block;
      padding-right: 11px;
      padding-top: 16px;
    }
    .header--button {
      margin-top: 13px;
    }
  }
  .application-list {
    flex-direction: column;
    align-items: center;
  }
}
