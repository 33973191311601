.modal {
  &-header {
    background: $light;
    border: 0;
  }

  .tab-content {
    min-height: 10px;
  }

  &-content {
    word-break: break-word;
  }
}
