.carousel-indicators {
  margin-bottom: 10px;

  li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid $green;
    background: $white;
    opacity: 1;

    &.active {
      background: $green;
    }
  }
}
