.nav {
  &-tabs {
    .nav-link {
      border-width: 0 0 2px 0;
      border-color: transparent;
      margin: 0;
      color: $gray-600;

      @extend .h5;

      &.active {
        border-color: currentColor;
        color: $green;
      }
    }
  }
}
