.app {
  &__container {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    padding: 0.25rem 0.25rem;
    // background: #FFFCF9;
    @extend .justify-content-center;

    .left-side {
      background: url('/images/bg.svg') no-repeat;
      background-size: cover;
      background-position: center bottom;
      width: 328px;
      text-align: center;
      position: relative;

      .logo {
        position: relative;
        max-width: 100%;
        @extend .v-align;
      }
    }

    .right-side {
      background: $white;
      max-width: 100%;
      padding: 5px 30px;
      margin-left: 20px;
      width: 480px;

      .logo-sm {
        margin-bottom: 2rem;
        display: none;
      }
      &.signup {
        height: 550px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px !important;
          background-color: transparent !important;
        }
        .app--form {
          .app-close {
            margin-top: -10px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  &--form {
    margin-top: 1rem;
    a.text-red:hover {
      text-decoration: underline;
      color: $primary;
    }
  }
}
.app--form {
  .app-close {
    margin-top: -10px;
    margin-bottom: 15px;
  }
}
.form-info {
  display: inline-block;
  background: $yellow;
  color: #ffffff;
  border-radius: 50%;
  padding: 0;
  margin-left: 5px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  text-transform: lowercase;
  top: -3px;
  position: relative;
}

.submit-sucess {
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  &-title {
    font-size: 24px;
    font-weight: 400;
    color: $primary;
    margin: 20px 10px 0;
  }
  &.bache-std {
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    height: fit-content;
    .message {
      font-size: 16px;
      text-align: left;
      background: $gray-100;
      padding: 10px;
      img {
        height: 70px;
        margin-bottom: 5px;
      }
    }
  }
}

.application-header {
  align-items: center;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  justify-content: space-between;
  padding: 10px 0px;

  &--title {
    font-size: 18px;
    text-transform: uppercase;
  }
}
.application-list {
  padding: 30px 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 0px !important;
    background-color: transparent !important;
  }
  .application {
    box-shadow: 1px 0px 10px #00000026;
    padding: 20px;
    width: 400px;
    min-height: 184px;
    background-color: $white;
    position: relative;
    margin-right: 22px;
    margin-bottom: 35px;
    cursor: pointer;
    &:hover {
      box-shadow: 2px 2px 20px #00000010;
    }
    .badge-f {
      font-size: 10px;
      font-weight: 600;
      height: fit-content;
      margin-left: 10px;
    }
    &--title {
      font-size: 16px;
      font-weight: 600;
      color: $primary;
      margin-bottom: 10px;
    }
    &--label {
      color: #1c0f10;
      font-weight: 600;
    }
    .additional {
      position: relative;
      table {
        tr {
          vertical-align: baseline;
        }
      }
    }
    .closed-application {
      background: $primary;
      position: absolute;
      bottom: 10px;
      right: 0px;
      // height: 40px;
      // width: 40px;
      // display: flex;
      // justify-content: flex-end;
      color: white;
      border-radius: 12px 0px 0px 12px;
      padding: 1px 15px 0px 12px;
      .notify {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        font-family: 'Segoe UI', sans-serif;
      }
    }
  }
}
.span.icon-check-fill.icon {
  position: relative;
  top: 2px;
}

.photo {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  .photo--preview {
    display: inline-block;
    height: 120px;
    border-radius: 2px;
    border: 1px solid $gray-300;
    padding: 2px;
    margin-right: 10px;
    img {
      height: 100%;
    }
  }
}
.preview-file {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  z-index: 999999;
  background-color: rgb(82, 86, 89);
  padding-bottom: 1rem;
  .preview--head {
    display: flex;
    color: white;
    padding: 16px 20px;
    justify-content: space-between;
    .back-link,
    .download {
      .btn-link {
        color: #d8d8d8;
        span {
          color: #d8d8d8;
        }
        &:hover {
          text-decoration: none;
        }
      }
      a {
        cursor: pointer;
        color: white;
        &:hover {
          color: white;
        }
      }
    }
  }
  .preview--body {
    margin: auto;
    padding: 1rem 1.25rem;
    display: block;
    text-align: center;
    justify-content: center;
    height: calc(100vh - 70px);
    overflow: auto;

    @extend .scrollbar;

    img {
      max-width: 100%;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .photo {
    .photo--preview {
      img {
        height: 100%;
        max-width: 140px;
      }
    }
  }
  .preview {
    .name {
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
