@include only-for-mobile {
  .app {
    &__wrapper {
      .left-side {
        display: none;
      }
      .right-side {
        margin: auto;
        padding: 2.25rem 1.25rem;
        width: fit-content;
        .logo-sm {
          display: block;
        }
      }
    }
  }

  .tab-content {
    padding: 1rem 1.25rem 1rem 1.25rem;

    .title {
      font-size: 16px;
      text-transform: uppercase;
      margin-left: -0.25rem;

      img {
        margin-right: 6px;
      }
    }
  }

  .title-list {
    display: none;
  }

  .background .header-content {
    padding: 70px 0px;
  }
  .background .header .resource .text {
    font-size: 16px;
  }
  .view-content {
    .content-list {
      padding: 10px 0px;
      margin-bottom: 0px;
      &-title {
        color: $primary;
        font-size: 18px;
        padding: 5px 15px;
        span {
          display: block;
          width: 40px;
          height: 37px;
          margin: -5px -15px;
          line-height: 40px;
        }
      }
      &-body {
        @include transition-css(all, 0.3s, ease-in-out);
        overflow: hidden;
        max-height: 0;

        &.show {
          max-height: 100%;
        }
      }
    }
  }
  .trinity-footer {
    display: block;
    .logo {
      margin-bottom: 10px;
    }
  }
  // .landing-cover{
  //     background-size: cover;
  //     .app__container{
  //         height: fit-content;
  //     }
  // }
  .login-landing {
    height: auto;
    .left-side {
      width: 100%;
      max-width: 100%;
      display: block;
      .left-content {
        padding: 20px;
      }
    }
    .right-side {
      .app__container {
        height: fit-content;
        .right-side {
          margin-left: 0px;
        }
      }
    }
  }
  .criteria-box {
    height: fit-content;
  }

  .mob-l-mar {
    margin: auto;
  }
  .resource {
    margin-top: 30px;
    &-title {
      h4 {
        font-size: 20px;
      }
    }
    &-body {
      font-size: 16px;
    }
    &-sub-title {
      font-size: 16px;
    }
  }
  .app__container .right-side.signup {
    height: fit-content;
  }
}
