@font-face {
  font-family: 'fuseclassroom';
  src: url('../../fonts/fuseclassroom.eot?8ndq3e');
  src: url('../../fonts/fuseclassroom.eot?8ndq3e#iefix') format('embedded-opentype'),
    url('../../fonts/fuseclassroom.ttf?8ndq3e') format('truetype'),
    url('../../fonts/fuseclassroom.woff?8ndq3e') format('woff'),
    url('../../fonts/fuseclassroom.svg?8ndq3e#fuseclassroom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fuseclassroom', fantasy !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn-po {
  position: relative;
  top: 2px;
}
.icn-po-4 {
  position: relative;
  top: 4px;
}
.icn-xm {
  font-size: 12px;
}
.icn-sm {
  font-size: convertRem(10px);
}
.icn-lg {
  font-size: convertRem(22px);
  line-height: 0.75em;
  vertical-align: -15%;
}
.icn-md {
  font-size: convertRem(16px);
  line-height: 0.75em;
  vertical-align: -15%;
}
.icn-2x {
  font-size: 2em;
}
.icn-3x {
  font-size: 3em;
}
.icn-4x {
  font-size: 4em;
}
.icn-5x {
  font-size: 5em;
}
.icn-fw {
  width: 1.28571429em;
  text-align: center;
}

.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icon-like-fill:before {
  content: '\e900';
}
.icon-like:before {
  content: '\e927';
}
.icon-attendance:before {
  content: '\e92e';
}
.icon-clip:before {
  content: '\e92f';
}
.icon-phone:before {
  content: '\e930';
}
.icon-place:before {
  content: '\e931';
}
.icon-github:before {
  content: '\e932';
}
.icon-linkedin:before {
  content: '\e933';
  color: #0077b5;
}
.icon-edit-line:before {
  content: '\e934';
}
.icon-angle:before {
  content: '\e935';
}
.icon-three-dots:before {
  content: '\e936';
}
.icon-move:before {
  content: '\e937';
}
.icon-notification:before {
  content: '\e938';
}
.icon-menu:before {
  content: '\e939';
}
.icon-home:before {
  content: '\e93a';
}
.icon-message:before {
  content: '\e93b';
}
.icon-search:before {
  content: '\e93c';
}
.icon-circle-close:before {
  content: '\e913';
}
.icon-circle-check:before {
  content: '\e915';
}
.icon-check:before {
  content: '\e914';
}
.icon-dot:before {
  content: '\e916';
}
.icon-feedback:before {
  content: '\e911';
}
.icon-clock:before {
  content: '\e910';
}
.icon-announcement:before {
  content: '\e903';
}
.icon-arrow:before {
  content: '\e904';
}
.icon-assignment:before {
  content: '\e905';
}
.icon-dashboard:before {
  content: '\e906';
}
.icon-discussion:before {
  content: '\e907';
}
.icon-download:before {
  content: '\e908';
}
.icon-file:before {
  content: '\e909';
}
.icon-folder:before {
  content: '\e90a';
}
.icon-grade:before {
  content: '\e90b';
}
.icon-live:before {
  content: '\e90c';
}
.icon-online-learning:before {
  content: '\e90d';
}
.icon-overview:before {
  content: '\e90e';
}
.icon-photo-roster:before {
  content: '\e90f';
}
.icon-play:before {
  content: '\e912';
}
.icon-recorded:before {
  content: '\e917';
}
.icon-a-plus:before {
  content: '\e918';
}
.icon-attendence:before {
  content: '\e919';
}
.icon-checkbox:before {
  content: '\e91a';
}
.icon-circle-tick:before {
  content: '\e91b';
}
.icon-comment:before {
  content: '\e91c';
}
.icon-photo:before {
  content: '\e922';
}
.icon-plus:before {
  content: '\e923';
}
.icon-quiz:before {
  content: '\e924';
}
.icon-radio:before {
  content: '\e925';
}
.icon-textarea:before {
  content: '\e926';
}
.icon-arrow-right:before {
  content: '\e928';
}
.icon-duplicate:before {
  content: '\e929';
}
.icon-delete:before {
  content: '\e92a';
}
.icon-cross:before {
  content: '\e92b';
}
.icon-student-status:before {
  content: '\e92c';
}
.icon-edit:before {
  content: '\e92d';
}
.icon-settings:before {
  content: '\e91d';
}
.icon-lock:before {
  content: '\e91e';
}
.icon-file-o:before {
  content: '\e91f';
}
.icon-preview:before {
  content: '\e920';
}
.icon-pdf:before {
  content: '\e921';
}
.icon-picture:before {
  content: '\e93d';
}
.icon-section:before {
  content: '\e93e';
}
.icon-chatbot:before {
  content: '\e93f';
}
.icon-save:before {
  content: '\e940';
}
.icon-presentation:before {
  content: '\e941';
}
.icon-e-book:before {
  content: '\e942';
}
.icon-reload:before {
  content: '\e943';
}
.icon-alert:before {
  content: '\e944';
}
.icon-cloud:before {
  content: '\e945';
}
.icon-info:before {
  content: '\e946';
}
.icon-run:before {
  content: '\e947';
}
.icon-close-octagon:before {
  content: '\e948';
}
.icon-chrome:before {
  content: '\e949';
}
.icon-video:before {
  content: '\e94a';
}
.icon-mic:before {
  content: '\e94b';
}
.icon-minus:before {
  content: '\e94c';
}
.icon-volume:before {
  content: '\e94d';
}
.icon-zoom-out:before {
  content: '\e94e';
}
.icon-zoom-in:before {
  content: '\e94f';
}
.icon-six-dots:before {
  content: '\e950';
}
.icon-approval:before {
  content: '\e951';
}
.icon-pause:before {
  content: '\e952';
}
.icon-volume-mute:before {
  content: '\e954';
}
.icon-person:before {
  content: '\e953';
}
.icon-poll:before {
  content: '\e955';
}
.icon-link:before {
  content: '\e956';
}
.icon-attachment:before {
  content: '\e957';
}
.icon-check-dash:before {
  content: '\e958';
}
.icon-view:before {
  content: '\e959';
}
.icon-hide:before {
  content: '\e95a';
}
.icon-audio-record:before {
  content: '\e95b';
}
.icon-stop:before {
  content: '\e95c';
}
.icon-hamburger-menu:before {
  content: '\e95d';
}
