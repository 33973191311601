// $primary: #2096f5;
$secondary: #fff;
$textColor: #595959;
$text-disabled: #d8d8d8;

.nepali-date-picker {
  position: relative;

  .calender {
    background: $secondary none repeat scroll 0 0;
    // border: 1px solid darken($secondary, 20%);
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: $textColor;
    display: block;
    font-family: NotoSans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    height: auto;
    letter-spacing: 0.2px;
    line-height: 1.25em;
    padding: 15px;
    position: absolute;
    text-align: right;
    user-select: none;
    // width: 300px;
    z-index: 9999;

    .calendar-controller {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      .date-indicator {
        display: flex;
        margin: 0px auto;
      }

      .control {
        cursor: pointer;
        position: relative;
        text-align: center;

        &.icon-today {
          position: absolute;
          right: 20px;
        }

        &.month,
        &.year {
          border: 1px solid #eee;
          line-height: 24px;
          width: 100px;

          .current-month,
          .current-year {
            display: block;
            padding: 4px;
          }
        }

        &.year {
          border-left: none;
        }

        .drop-down {
          background-color: #fff;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
          left: 0;
          max-height: 200px;
          overflow-y: scroll;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 100;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }

          li {
            padding: 4px 10px;

            &.active {
              background: $primary;
              color: #fff;
            }
          }
        }
      }
    }

    td.month-day {
      &.current {
        opacity: 1;
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }

      &.disabled {
        color: $text-disabled;
      }

      &.today {
        color: #4a4a4a;
        position: relative;

        &::before {
          background-color: $primary;
          border-radius: 50%;
          bottom: 6px;
          content: '';
          height: 4px;
          left: 50%;
          margin: auto;
          position: absolute;
          transform: translateX(-50%);
          width: 4px;
        }
      }

      &.selected {
        color: #fff;
        position: relative;
        &:hover {
          color: #fff;
        }
        &::after {
          background: $primary;
          border-radius: 50%;
          content: '';
          height: 35px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 35px;
          z-index: -1;
        }
      }
    }

    table {
      text-align: center;
      width: 100%;

      td {
        height: 40px;
        width: 40px;
      }

      tr {
        height: 36px;
      }
    }
  }
}
